import {Auth, Amplify} from 'aws-amplify'
import {CognitoUser} from '@aws-amplify/auth'



export class AuthService{
    // constructor(region: string, userPoolId: string, clientId: string){
    // }
    public async login(poolId: string, clientId: string, username: string, password: string){
        try {

            const region = poolId.split('_')[0];
            // console.log('new region: ', newRegion)
            console.log('Data Input (Region, User Pool ID, Client ID, Username', region, poolId, clientId, username);
            
            await Amplify.configure({
                Auth: {
                    mandatorySignIn: false,
                    region: region,
                    userPoolId: poolId,
                    userPoolWebClientId: clientId,
                    authenticationFlowType: 'USER_PASSWORD_AUTH'
                }
            })
            const user = await Auth.signIn(username, password) as CognitoUser;
            // console.log('from authService: ', user)
            return user;
        } catch (error) {
            if(error instanceof Error) {
                console.log("log error in amplify config: ", error.name, error.message)
                return error.message;
            }
        }
    }
}