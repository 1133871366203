import React from 'react'
import './AwsKey.css'
const AwsKey = (props : any) => {
    // console.log("from awskey: ", props.user)
    // let msg = "OK"
    // let fullRes;
    // if(!props.token.username){
    //   msg = props.token
    // }else{
    // }
    const fullRes = JSON.stringify(props.token, null, 5)
    // console.log('fullRes: ', fullRes)
    // const userName = props.user.username
    // const poolId = props.user.pool.userPoolId
    // const clientId = props.user.pool.clientId
    // const endPoint = props.user.pool.client.endpoint
    // const authFlowType = props.user.authenticationFlowType

    // const idToken = props.user.signInUserSession.idToken.jwtToken
    // const accessToken = props.user.signInUserSession.accessToken.jwtToken
    // const refreshToken = props.user.signInUserSession.refreshToken.token
    // const payload = JSON.stringify(props.user.signInUserSession.idToken.payload)
    // const sub = JSON.parse(payload).sub;
    // const emailVerified = JSON.parse(payload).email_verified;
    // const iss = JSON.parse(payload).iss;
    // const aud = JSON.parse(payload).aud;
    // const authTime = JSON.parse(payload).auth_time;
    // const exp = JSON.parse(payload).exp;
    // const timeOut = (+exp - Date.now()/1000)/60;
    // const iat = JSON.parse(payload).iat;
    // const jti = JSON.parse(payload).jti;
    // const email = JSON.parse(payload).email;
  return (
    <div className='token'>
      
      {<pre>{fullRes}</pre>}
      
    </div>
  )
}

export default AwsKey;