import React, { useEffect, useState } from 'react';
// import './App.css';
import {AuthService} from './services/AuthService'
import {config} from './config'
// import { getAmplifyUserAgent } from '@aws-amplify/core';
// import { CognitoUser } from '@aws-amplify/auth';
import AwsKey from './components/aws-cognito/AwsKey';
import CognitoInput from './components/cognito-input/CognitoInput';
import {Typography, AppBar, Card, CardActions, CardContent, CardMedia, CssBaseline, Grid, Toolbar, Container, Button } from '@mui/material'
import { PhotoCamera } from '@mui/icons-material';
import CloudCircleIcon from '@mui/icons-material/CloudCircle';
import {makeStyles} from '@mui/material';


function App() {
  
  const [token, setToken] = useState()

  const getCognitoDataHandler = (token : any) =>{
    //data: region, poolId, clientId, username, password
    setToken(token);
    // setMsg(msg);
  }

  return (
    <div>
      
      <h3>Hello World!</h3>
      <CognitoInput onGetCognitoData={getCognitoDataHandler} />
      <AwsKey token={token} />
    </div>
  );
}

export default App;
