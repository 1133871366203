import { CognitoUser } from '@aws-amplify/auth';
import React, { useEffect } from 'react'
import { useState } from 'react';
import { AuthService } from '../../services/AuthService';
import TextField from '@mui/material/TextField';

const CognitoInput = (props) => {
    const [poolId, setPoolId] = useState('');
    const [clientId, setClientId] = useState('')
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('')

    const [msg, setMsg] = useState(null);
    const [showButton, setShowButton] = useState(false)
    useEffect(()=>{
        
        const result = validateInputs();
        if(result === "OK"){
            //input is valid, enable button
            setShowButton(true)
        }else{
            setShowButton(false)
            setMsg(result);
        }
    },[poolId, clientId, username, password])


    const getPoolIdHandler = e => {
        setPoolId(e.target.value.trim())
    }
    const getClientIdHandler = e => {
        setClientId(e.target.value.trim())
    }
    const getUsernameHandler = e => {
        setUsername(e.target.value.trim())
    }
    const getPasswordHandler = e => {
        setPassword(e.target.value.trim())
    }

    const validateInputs = () =>{
        if(poolId.length < 17){
            return "Wrong User Pool ID";
        }else if(clientId.length <26){
            return "Wrong Cognito Client App ID"
        }else if(username.length === 0){
            return "Username cannot be empty."
        }else if(password.length === 0){
            return "Password cannot be empty."
        }
        return "OK"
    }
    
    const submitHandler = async (e) =>{
        e.preventDefault();
        
        const data = {
         
            poolId: poolId,
            clientId: clientId,
            username: username,
            password: password
        }
        // console.log('from input: ',data)
        const tmpToken = await getCognitoData(data)
        // let tmpMsg;
        // if(!tmpToken.username){
        //     tmpMsg=tmpToken
        // }
        console.log('Data Output: ', tmpToken)
        // console.log('msg in submit: ', msg)
        props.onGetCognitoData(tmpToken, msg);
    } //end submit handler
    const getCognitoData = async (data) => {
        
        try {
            const auth = new AuthService();
            const tokenTmp = await auth.login(data.poolId, data.clientId, data.username, data.password);
            setMsg('OK')
            return tokenTmp;
        } catch (error) {
            console.log("WRONG Authentication: ", error.message)
            return error.message;
            // setMsg(error.message)
        }
        
    }
  return (
    <form onSubmit={submitHandler}>

        <div>
            <label htmlFor='cognito-poolId' >Cognito Pool ID</label>
            <input type='text' id='cognito-poolId' onChange={getPoolIdHandler}></input>
        </div>
        <div>
            <label htmlFor='cognito-clientId' >Cognito Client ID</label>
            <input type='text' id='cognito-clientId' onChange={getClientIdHandler}></input>
        </div>
        <div>
            <label htmlFor='cognito-username' >Cognito Username</label>
            <input type='text' id='cognito-username' onChange={getUsernameHandler}></input>
        </div>
        <div>
            <label htmlFor='cognito-password' >Cognito Password</label>
            <input type='password' id='cognito-password' onChange={getPasswordHandler}></input>
        </div>
        <div>
            {showButton && <button type='submit'>Submit</button>}
            {!showButton && <button type='submit' disabled>Submit</button>}
            
        </div>
    </form>
  )
}

export default CognitoInput;